import CopyIcon from "../../Image/PublicPage/copyIcon.svg";
import { message } from "antd";
export default function PublicPage() {
  const [messageApi, contextHolder] = message.useMessage();

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(
        "http://inwabysurlm7mzqykpadiehy3stabphhtsfbr3ikt6az4ztqwgxfgqqd.onion/"
      )
      .then(() => {
        messageApi.success("Link copied to clipboard!");
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };
  const link =
    "http://inwabysurlm7mzqykpadiehy3stabphhtsfbr3ikt6az4ztqwgxfgqqd.onion/";
  return (
    <div className="flex flex-col gap-6 md:gap-10 justify-center items-center px-6 py-10">
      {contextHolder}
      <div className="flex flex-col gap-5 md:gap-6 items-center justify-center ">
        {/* <h2 className="text-lg md:text-4xl text-[#5956E9] font-semibold">
          indiawhistleblower.com
        </h2> */}
        <h1 className="text-3xl md:text-5xl text-[#5956E9] font-semibold w-full  text-center">
          Blow the whistle for the nation!
        </h1>
        <p className="text-[#727272] text-base text-center md:text-xl">
          Be a whistleblower for a progressive India
        </p>
      </div>

      <div className="flex flex-col gap-6 items-center justify-center">
        <p className="text-[#727272] text-lg md:text-2xl font-medium text-center w-[90%] md:w-full">
          Your identity is 100% anonymous and secure
        </p>
        <h2 className="text-sm md:text-base text-black font-medium w-full md:w-[70%] text-center">
          To submit your files anonymously on our .onion website please follow
          these steps
        </h2>
      </div>
      <div className="flex flex-col gap-4">
        <div className="border border-[#A3A3A3] rounded-2xl flex">
          <div className="flex flex-col justify-center items-center bg-[#E4E4FF] rounded-s-2xl px-8 py-3">
            <p className="text-[#5956E9] text-xs md:text-sm">Step</p>
            <p className="text-[#5956E9] text-2xl md:text-4xl font-semibold">
              01
            </p>
          </div>
          <div className="flex  flex-col justify-center py-2 gap-2">
            <p className="text-black text-sm md:text-base font-medium ps-3 pe-2 md:pe-6  max-w-max md:min-w-[500px]">
              Download and install the Tor browser from here:
            </p>
            <a
              href="https://www.torproject.org/download"
              target="_blank"
              className="underline text-xs md:text-sm text-[#5956E9] ps-3 pe-2 md:pe-6 "
            >
              https://www.torproject.org/download
            </a>
          </div>
        </div>
        <div className="border border-[#A3A3A3] rounded-2xl flex">
          <div className="flex flex-col justify-center items-center bg-[#E4E4FF] rounded-s-2xl px-8 py-3">
            <p className="text-[#5956E9] text-xs md:text-sm">Step</p>
            <p className="text-[#5956E9] text-2xl md:text-4xl font-semibold">
              02
            </p>
          </div>
          <div className="flex justify-center flex-col py-2 gap-2">
            <p className="text-black text-sm md:text-base font-medium ps-3 pe-2 md:pe-6  max-w-max md:min-w-[500px]">
              Copy the following URL:{" "}
            </p>
            <div className="flex border justify-center items-center border-[#A3A3A3] rounded-lg max-w-max ms-3">
              <p className="truncate-link  underline text-xs md:text-sm text-[#5956E9] ps-3 pe-2 md:pe-6 ">
                {link}
              </p>
              <div
                className="bg-[#5956E9] rounded-e-lg cursor-pointer"
                onClick={copyToClipboard}
              >
                <img
                  src={CopyIcon}
                  alt="CopyIcon"
                  className="px-3 py-2 w-[40px]"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="border border-[#A3A3A3] rounded-2xl flex max-w-max md:min-w-[500px]">
          <div className="flex flex-col justify-center items-center bg-[#E4E4FF] rounded-s-2xl px-8 py-3">
            <p className="text-[#5956E9] text-xs md:text-sm">Step</p>
            <p className="text-[#5956E9] text-2xl md:text-4xl font-semibold">
              03
            </p>
          </div>
          <div className="flex items-center">
            <p className="text-black text-sm md:text-base font-medium ps-3 pe-6 w-full py-2 md:w-[80%]">
              Paste the .onion link in the Tor browser to access the secure page
              to submit the information.
            </p>
          </div>
        </div>
      </div>

      <p className="text-[#727272] text-sm md:text-base text-center w-[90%] md:w-[50%]">
        <span className="text-black font-semibold">Please note</span> : We do
        not log IP addresses, and we use the Tor network to further protect your
        identity. Once verified, the submitted information will be shared with
        trusted journalists, NGOs, or relevant authorities to ensure it reaches
        those who can take action.
      </p>
    </div>
  );
}
